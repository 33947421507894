import React, { useEffect, useState } from 'react';
import { Formik, Form, useFormik } from 'formik';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { validationSchema, initialValues } from './Step2.constants';
import {
  backButtonStyles,
  buttonContainerStyles,
  buttonStyles,
  containerStyles,
  descriptionStyles,
  dropdownLastStyles,
  dropdownStyles,
  errorStyles,
  formContainerStyles,
  formStyles,
  inputStyles,
  radioButtonLastStyles,
  radioButtonStyles,
  spanStyles,
  titleStyles
} from './Step2.styles';
import { IStep2 } from './Step2.models';
import { contentStyles } from '../Step1/Step1.styles';
import { removeLastExtension } from '../../../../helpers/removeExtension';

type ModelTypeOption = {
  value: string;
  label: string;
};

type ModelTypeOptions = {
  classification: ModelTypeOption[];
  regression: ModelTypeOption[];
};

const Step2: React.FC<IStep2> = ({ arrayOfNumericalColumns, step2Data, step1Data, setStep2Data, setActiveStep }) => {
  const modelTypeOptions: ModelTypeOptions = {
    classification: [
      { value: 'NN', label: 'Neural Nets' },
      { value: 'xgboost', label: 'XGBoost' },
      { value: 'RandomForest', label: 'Random Forest' },
      { value: 'logistic_regression', label: 'Logistic Regression' }
    ],
    regression: [
      { value: 'NN', label: 'Neural Nets' },
      { value: 'xgboost', label: 'XGBoost' },
      { value: 'RandomForest', label: 'Random Forest' },
      { value: 'linear_regression', label: 'Linear Regression' }
    ]
  };

  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      problemType: step2Data.problemType?.length > 0 ? step2Data.problemType : 'classification',
      modelType: step2Data.modelType?.length > 0 ? step2Data.modelType : '',
      targetColumn: step2Data.targetColumn?.length > 0 ? step2Data.targetColumn : ''
    },
    validationSchema,
    onSubmit
  });

  const { values, setFieldValue, handleChange, errors, touched } = formik;

  const handleAutocompleteChange = (event: any, value: any) => {
    setFieldValue('targetColumn', value);
  };

  const handleModelTypeChange = (event: any, value: ModelTypeOption | null) => {
    setFieldValue('modelType', value ? value.value : '');
  };

  useEffect(() => {
    let modelType = values.modelType;
    // if (modelType === 'logistic_regression' || modelType === 'linear_regression') {
    //   modelType = 'sklearn';
    // }
    setIsFormValid(values.problemType?.length > 0 && values.targetColumn?.length > 0);
    setStep2Data({ ...values, modelType });
  }, [errors, touched, values]);

  function onBackClick() {
    setActiveStep(1);
    // if (modelType === 'logistic_regression' || modelType === 'linear_regression') {
    //   modelType = 'sklearn';
    // }
  }

  function onSubmit() {
    setActiveStep(3);
  }

  return (
    <Formik initialValues={step2Data} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form style={formContainerStyles}>
        <Grid item xs={12} md={12} style={containerStyles}>
          <div style={contentStyles}>
            <Grid item xs={12}>
              <span style={titleStyles}>Model Specifications</span>
            </Grid>
            <Grid item xs={12}>
              <span style={descriptionStyles}>Select Problem Type</span>
            </Grid>
            <Grid item xs={12} md={12} style={formStyles}>
              <label style={radioButtonStyles}>
                <input
                  type="radio"
                  name="problemType"
                  value="classification"
                  onChange={handleChange}
                  checked={values.problemType === 'classification'}
                  style={inputStyles}
                />
                <span style={spanStyles}>Classification</span>
              </label>
              <label style={radioButtonLastStyles}>
                <input
                  type="radio"
                  name="problemType"
                  value="regression"
                  onChange={handleChange}
                  checked={values.problemType === 'regression'}
                  style={inputStyles}
                />
                <span style={spanStyles}>Regression</span>
              </label>
              {touched.problemType && typeof errors.problemType === 'string' && (
                <div style={errorStyles}>{errors.problemType}</div>
              )}
            </Grid>

            <Grid item xs={12} md={12} style={dropdownStyles}>
              <p
                style={{
                  color: '#333333',
                  margin: '6px 0',
                  width: '50%',
                  fontSize: '16px',
                  fontWeight: '400',
                  fontFamily: 'Circular Std Book'
                }}
              >
                Select Model
              </p>
              <Autocomplete
                style={{ width: '455px' }}
                options={modelTypeOptions[values.problemType as keyof typeof modelTypeOptions]}
                getOptionLabel={(option) => option.label}
                value={
                  modelTypeOptions[values.problemType as keyof typeof modelTypeOptions].find(
                    (option) => option.value === values.modelType
                  ) || null
                }
                onChange={handleModelTypeChange}
                renderOption={(props, option) => (
                  <li {...props} style={{ fontFamily: 'Circular Std Book' }}>
                    {option.label} {/* Ensure options use the correct font */}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    InputProps={{
                      ...params.InputProps,
                      style: { fontFamily: 'Circular Std Book' } // Apply font to the selected input value
                    }}
                  />
                )}
                componentsProps={{
                  clearIndicator: {
                    onClick: () => setFieldValue('modelType', '')
                  }
                }}
                disableClearable={!values.modelType}
              />

              {touched.modelType && typeof errors.modelType === 'string' && (
                <div style={errorStyles}>{errors.modelType}</div>
              )}
            </Grid>

            <Grid item xs={12} md={12} style={dropdownLastStyles}>
              <p
                style={{
                  color: '#333333',
                  margin: '6px 0',
                  width: '50%',
                  fontSize: '16px',
                  fontWeight: '400',
                  fontFamily: 'Circular Std Book'
                }}
              >
                Select Target Column
              </p>
              <Autocomplete
                style={{ width: '455px' }}
                options={arrayOfNumericalColumns} // Assuming this is an array of strings or objects
                value={values.targetColumn || null} // Ensure null if no value is selected
                onChange={handleAutocompleteChange} // Handle change appropriately
                renderOption={(props, option) => (
                  <li {...props} style={{ fontFamily: 'Circular Std Book' }}>
                    {typeof option === 'string' ? option : option.label} {/* Handle strings and objects */}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    InputProps={{
                      ...params.InputProps,
                      style: { fontFamily: 'Circular Std Book' } // Apply font to selected input
                    }}
                  />
                )}
                componentsProps={{
                  clearIndicator: {
                    onClick: () => setFieldValue('targetColumn', '')
                  }
                }}
                disableClearable={!values.targetColumn}
              />
              {arrayOfNumericalColumns.length === 0 && (
                <div style={errorStyles}>
                  The dataset '{removeLastExtension(step1Data.uploadedFile)}' does not contain numerical columns, please
                  select another one in the 1st step.
                </div>
              )}

              {touched.targetColumn && typeof errors.targetColumn === 'string' && (
                <div style={errorStyles}>{errors.targetColumn}</div>
              )}
            </Grid>
          </div>
          <Grid item xs={12} md={12} sx={buttonContainerStyles}>
            <Button variant="contained" size="large" style={backButtonStyles} sx={buttonStyles} onClick={onBackClick}>
              Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{
                width: '120px',
                height: '60px',
                borderRadius: '8px',
                fontFamily: 'Circular Std Book',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                backgroundColor: !isFormValid ? '#d9d9d9' : '#334063',
                color: !isFormValid ? '#635F5F' : '#fff',
                float: 'right'
              }}
              sx={buttonStyles}
              onClick={onSubmit}
              disabled={!isFormValid}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default Step2;
