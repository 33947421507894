import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from '@mui/material';
import { dispatch } from '../../store/hooks';
import { modalsMiddleware } from '../../store/slices/modals';
import { ModalName } from '../../types/modals';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { IconInfoCircle } from '@tabler/icons';
import {
  classificationMetricsOptions,
  criterionClassificationOptions,
  criterionRegressionOptions,
  layerOptions,
  regressionMetricsOptions
} from '../../helpers/managers';
import { datasourceUsersMiddleware } from '../../store/slices/datasourceUsers';
import { isNaN } from 'formik';

export interface ModelInfoDialogProps {
  modelInfo: string;
  problemType: string;
  params: any;
  setParams: any;
  paramsCatboost: any;
  setParamsCatboost: any;
  catboostSaveObject: any;
  setCatboostSaveObject: any;
  catboostReset: any;
}

const CatboostDialog = ({
  modelInfo,
  problemType,
  params,
  setParams,
  paramsCatboost,
  setParamsCatboost,
  catboostSaveObject,
  setCatboostSaveObject,
  catboostReset
}: ModelInfoDialogProps) => {
  const theme = useTheme();

  const initialValues = {
    catboostToggle: true,
    metric: problemType === 'classification' ? classificationMetricsOptions[0] : regressionMetricsOptions[0],
    time: 0.1,
    catboostLearningRate: 0.3,
    catboostNumberOfTrees: 100,
    catboostMaximumDepth: 6,
    catboostL2Leaf: 10,
    catboostBagging: 1
  };
  const [catboostToggle, setCatboostToggle] = useState(
    catboostSaveObject.catboostToggle ?? initialValues.catboostToggle
  );
  const [catboostMetrics, setCatboostMetrics] = useState(catboostSaveObject.metric ?? initialValues.metric);
  const [catboostTime, setCatboostTime] = useState(catboostSaveObject.time ?? initialValues.time);
  const [catboostLearningRate, setCatboostLearningRate] = useState(
    catboostSaveObject.catboostLearningRate ?? initialValues.catboostLearningRate
  );
  const [catboostNumberOfTrees, setCatboostNumberOfTrees] = useState(
    catboostSaveObject.catboostNumberOfTrees ?? initialValues.catboostNumberOfTrees
  );
  const [catboostMaximumDepth, setCatboostMaximumDepth] = useState(
    catboostSaveObject.catboostMaximumDepth ?? initialValues.catboostMaximumDepth
  );
  const [catboostL2Leaf, setCatboostL2Leaf] = useState(
    catboostSaveObject.catboostL2Leaf ?? initialValues.catboostL2Leaf
  );
  const [catboostBagging, setCatboostBagging] = useState(
    catboostSaveObject.catboostBagging ?? initialValues.catboostBagging
  );
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    if (catboostToggle) {
      if (catboostMetrics === undefined || !catboostMetrics || isNaN(catboostTime)) {
        setDisableSave(true);
      } else {
        setDisableSave(false);
      }
    } else {
      if (
        isNaN(catboostLearningRate) ||
        isNaN(catboostNumberOfTrees) ||
        isNaN(catboostMaximumDepth) ||
        isNaN(catboostL2Leaf) ||
        isNaN(catboostBagging)
      ) {
        setDisableSave(true);
      } else {
        setDisableSave(false);
      }
    }
  }, [
    catboostMetrics,
    catboostTime,
    catboostLearningRate,
    catboostNumberOfTrees,
    catboostMaximumDepth,
    catboostL2Leaf,
    catboostBagging,
    catboostToggle
  ]);

  const handleReset = () => {
    setCatboostMetrics(initialValues.metric);
    setCatboostTime(initialValues.time);
    setCatboostLearningRate(initialValues.catboostLearningRate);
    setCatboostNumberOfTrees(initialValues.catboostNumberOfTrees);
    setCatboostMaximumDepth(initialValues.catboostMaximumDepth);
    setCatboostL2Leaf(initialValues.catboostL2Leaf);
    setCatboostBagging(initialValues.catboostBagging);
    catboostReset({});
  };

  const onModalClose = () => {
    dispatch(modalsMiddleware.closeModal(ModalName.CatboostInfoModal));
  };

  const handleCatboostToggle = () => {
    setCatboostToggle(!catboostToggle);
  };

  const handleCatboostMetrics = (e: any) => {
    const value = e.target.outerText;
    setCatboostMetrics(value);
  };

  const handleCatboostMaxTime = (e: any) => {
    const value = parseFloat(e.target.value);
    setCatboostTime(value);
  };

  const handleCatboostLearningRate = (e: any) => {
    let value = parseFloat(e.target.value);
    value = Math.min(Math.max(value, 0.00001), 0.99999);

    if (value !== parseInt(e.target.value, 10)) {
      e.target.value = value;
    }
    setCatboostLearningRate(value);
  };

  const handleCatboostNumberOfTrees = (e: any) => {
    let value = parseInt(e.target.value);
    value = Math.min(Math.max(value, 10), 500);

    if (value !== parseInt(e.target.value, 10)) {
      e.target.value = value;
    }
    setCatboostNumberOfTrees(value);
  };

  const handleCatboostMaxDepth = (e: any) => {
    let value = parseInt(e.target.value);
    value = Math.min(Math.max(value, 3), 10);

    if (value !== parseInt(e.target.value, 10)) {
      e.target.value = value;
    }
    setCatboostMaximumDepth(value);
  };

  const handleCatboostL2Leaf = (e: any) => {
    let value = parseInt(e.target.value);
    value = Math.min(Math.max(value, 1), 10);

    if (value !== parseInt(e.target.value, 10)) {
      e.target.value = value;
    }
    setCatboostL2Leaf(value);
  };

  const handleCatboostBagging = (e: any) => {
    let value = parseInt(e.target.value);
    value = Math.min(Math.max(value, 1), 10);

    if (value !== parseInt(e.target.value, 10)) {
      e.target.value = value;
    }
    setCatboostBagging(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === '.') {
      e.preventDefault();
    }
  };

  const onSaveClick = () => {
    setCatboostSaveObject({
      catboostToggle: catboostToggle,
      metric: typeof catboostMetrics === 'object' ? catboostMetrics.label : catboostMetrics,
      time: catboostTime,
      catboostLearningRate: catboostLearningRate,
      catboostNumberOfTrees: catboostNumberOfTrees,
      catboostMaximumDepth: catboostMaximumDepth,
      catboostL2Leaf: catboostL2Leaf,
      catboostBagging: catboostBagging
    });
    if (catboostToggle) {
      setParamsCatboost(() => ({
        ...params,
        models: {
          [modelInfo]: {
            one_model: !catboostToggle,
            time: catboostTime,
            metric: typeof catboostMetrics === 'object' ? catboostMetrics.label : catboostMetrics
          }
        }
      }));
    } else {
      setParamsCatboost(() => ({
        ...params,
        models: {
          [modelInfo]: {
            one_model: !catboostToggle,
            learning_rate: catboostLearningRate,
            n_estimators: catboostNumberOfTrees,
            depth: catboostMaximumDepth,
            l2_leaf_reg: catboostL2Leaf,
            bagging_temperature: catboostBagging
          }
        }
      }));
    }
    onModalClose();
  };

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onModalClose} aria-labelledby="simple-dialog-title" open>
      <div style={{ maxHeight: '100%' }}>
        <DialogTitle style={{ textAlign: 'center', position: 'relative', cursor: 'move' }} id="draggable-dialog-title">
          {`${modelInfo} Model Config`}
          <IconButton
            onClick={onModalClose}
            sx={{
              position: 'absolute',
              right: 20,
              top: 10
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Card>
          <CardContent sx={{ pt: 0 }}>
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', paddingTop: '10px' }}>
              <Tooltip title="The One Model  trains a specific model based on the parameters specified by the user.">
                <div
                  style={{
                    marginTop: '-15px'
                  }}
                >
                  <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                </div>
              </Tooltip>
              <div style={{ color: '#000', fontSize: '22px', marginRight: '10px' }}>One Model</div>
              <div
                style={{
                  width: '70px',
                  height: '35px',
                  borderRadius: '35px',
                  backgroundColor: catboostToggle ? 'green' : 'green',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  position: 'relative',
                  cursor: 'pointer',
                  padding: '0 5px',
                  boxSizing: 'border-box'
                }}
                onClick={handleCatboostToggle}
              >
                <div
                  style={{
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    transform: catboostToggle ? 'translateX(38px)' : 'translateX(0)',
                    transition: 'transform 0.2s ease-in-out',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                    cursor: 'pointer'
                  }}
                />
              </div>
              <div style={{ color: '#000', fontSize: '22px', marginLeft: '10px' }}>Best Model</div>
              <Tooltip title="The  Best Model gives the leading model determined by the algorithm based on the Metrics  and Time specified by the user.">
                <div
                  style={{
                    marginTop: '-15px'
                  }}
                >
                  <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                </div>
              </Tooltip>
            </div>
            {catboostToggle ? (
              <div>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  style={{ paddingBottom: '10px' }}
                >
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Metrics</p>
                      <Tooltip title="Select one of this parameters, which should be taken into account for model convergence.">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <Autocomplete
                      style={{ width: '100%' }}
                      options={
                        problemType === 'classification' ? classificationMetricsOptions : regressionMetricsOptions
                      }
                      value={typeof catboostMetrics === 'object' ? catboostMetrics.label : catboostMetrics}
                      onChange={handleCatboostMetrics}
                      renderInput={(params) => <TextField {...params} label="" />}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Time</p>
                      <Tooltip title="Select how long you are able to expect for completion of model training process in hours.">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      value={catboostTime}
                      onChange={handleCatboostMaxTime}
                      placeholder="Select Time"
                      inputProps={{ type: 'number', min: 0.1 }}
                    />
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  style={{ paddingBottom: '10px' }}
                >
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Learning Rate</p>
                      <Tooltip title="Hyperparameter, which scales each tree's contribution (or iteration) in the boosting process.">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={handleCatboostLearningRate}
                      type="text"
                      // onKeyDown={handleKeyDown}
                      value={catboostLearningRate}
                      inputProps={{
                        min: 0.00001,
                        step: 0.00001,
                        type: 'number',
                        max: 0.99999
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Number of Trees</p>
                      <Tooltip title="Specifies the number of trees in the model">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={handleCatboostNumberOfTrees}
                      type={'number'}
                      onKeyDown={handleKeyDown}
                      value={catboostNumberOfTrees}
                      inputProps={{
                        min: 10,
                        step: 1,
                        type: 'number',
                        max: 500
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  style={{ paddingBottom: '10px' }}
                >
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Maximum depth </p>
                      <Tooltip title="Specifies the maximum depth of each tree in the model">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={handleCatboostMaxDepth}
                      type={'number'}
                      onKeyDown={handleKeyDown}
                      value={catboostMaximumDepth}
                      inputProps={{
                        min: 3,
                        step: 1,
                        type: 'number',
                        max: 10
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>L2_leaf_reg</p>
                      <Tooltip title="L2 regularization coefficient applied to leaf values, which helps to avoid overfitting by adding a penalty for large leaf weights.">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={handleCatboostL2Leaf}
                      type={'number'}
                      onKeyDown={handleKeyDown}
                      value={catboostL2Leaf}
                      inputProps={{
                        min: 1,
                        step: 1,
                        type: 'number',
                        max: 10
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  style={{ paddingBottom: '10px' }}
                >
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Bagging Temperature</p>
                      <Tooltip title="Controls the randomness of the bagging process. A higher bagging temperature increases the diversity of the trees in the ensemble by sampling the dataset with more noise, while a lower value leads to more deterministic tree building.">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={handleCatboostBagging}
                      type={'number'}
                      onKeyDown={handleKeyDown}
                      value={catboostBagging}
                      inputProps={{
                        min: 0,
                        step: 1,
                        type: 'number'
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            )}

            <Grid item xs={12} md={4} sx={{ paddingX: '16px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    margin: '20px auto',
                    height: '52px',
                    background: theme.palette.warning.dark,
                    '&:hover': { background: theme.palette.warning.main },
                    color: 'grey.900'
                  }}
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: disableSave ? '#CCCCCC' : '#202090',
                    color: disableSave ? '#666666' : '#fff'
                  }}
                  sx={{ margin: '20px auto', height: '52px' }}
                  onClick={onSaveClick}
                  disabled={disableSave}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </Dialog>
  );
};

export default CatboostDialog;
